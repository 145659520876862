import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const KeywordScrap = () => {
  const [keyword, setKeyword] = useState("");
  const [email, setEmail] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      if (keyword && email) {
        const response = await axios.get(
          "https://scrape-backend.marktivities.guru/scraper/keywordScrap",
          {
            params: {
              keyword,
              tomail: email,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Keyword scraping has started");
        } else {
          toast.error("Failed to start keyword scraping");
        }
      } else {
        toast.error("Please specify keyword and email");
      }
    } catch (error) {
      console.error("Error processing request", error);
      toast.error("Internal Server Error!");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-6">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 text-center">
          Keyword Scraper
        </h2>

        <p className="text-gray-700 mb-6 text-center">
          Enter your keyword and email address. Results will be sent to the
          provided email once scraping is complete.
        </p>

        <div className="space-y-4">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Enter keyword"
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-800 bg-white"
          />

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-800 bg-white"
          />

          <button
            type="button"
            onClick={handleSubmit}
            className={`w-full py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isProcessing ? "bg-blue-400 cursor-not-allowed" : ""
            }`}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default KeywordScrap;
