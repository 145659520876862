import React from 'react';
import "../css/welcome.css"

const Welcome = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="welcome-animation text-5xl font-extrabold text-gray-900 shadow-lg px-8 py-4 rounded-lg">
        Welcome
      </div>
    </div>
  );
};

export default Welcome;