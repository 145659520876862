import { useState } from "react";
import "./App.css";
import { FaCloudUploadAlt, FaInfoCircle } from "react-icons/fa";
import CSVScrap from "./components/CSVScrap";
import KeywordScrap from "./components/KeywordScrap";
import SingleWebsiteScrap from "./components/SingleWebsiteScrap";
import Welcome from "./components/Welcome";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [view, setView] = useState("");

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="toast-container-custom"
      />

      <div className="flex flex-col min-h-screen bg-gradient-to-r from-blue-50 via-gray-100 to-blue-50">
        <div className="bg-white border-b border-gray-300 shadow-lg p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row justify-center">
            <div className="flex flex-wrap justify-center space-x-0 sm:space-x-6 space-y-2 sm:space-y-0">
              <button
                onClick={() => setView("csv-scrap")}
                className={`py-3 px-6 text-lg sm:text-xl font-semibold rounded-lg transition-transform duration-300 ease-in-out hover:scale-110 ${
                  view === "csv-scrap"
                    ? "bg-blue-600 text-white shadow-md"
                    : "text-gray-600 hover:text-blue-600 hover:bg-gray-100"
                }`}
              >
                <FaCloudUploadAlt className="inline-block mr-2 mb-1" /> CSV
                Scrap
              </button>
              <button
                onClick={() => setView("keyword-scrap")}
                className={`py-3 px-6 text-lg sm:text-xl font-semibold rounded-lg transition-transform duration-300 ease-in-out hover:scale-110 ${
                  view === "keyword-scrap"
                    ? "bg-blue-600 text-white shadow-md"
                    : "text-gray-600 hover:text-blue-600 hover:bg-gray-100"
                }`}
              >
                <FaInfoCircle className="inline-block mr-2 mb-1" /> Keyword
                Scrap
              </button>
              <button
                onClick={() => setView("single-website-scrap")}
                className={`py-3 px-6 text-lg sm:text-xl font-semibold rounded-lg transition-transform duration-300 ease-in-out hover:scale-110 ${
                  view === "single-website-scrap"
                    ? "bg-blue-600 text-white shadow-md"
                    : "text-gray-600 hover:text-blue-600 hover:bg-gray-100"
                }`}
              >
                <FaInfoCircle className="inline-block mr-2 mb-1" /> Single
                Website Scrap
              </button>
            </div>
          </div>
        </div>

        <div className="flex-1 flex justify-center items-center p-4 sm:p-6">
          <div className="w-full max-w-4xl p-6 sm:p-10 bg-white shadow-2xl rounded-3xl transition-transform duration-300 transform ">
            {view === "csv-scrap" && <CSVScrap />}
            {view === "keyword-scrap" && <KeywordScrap />}
            {view === "single-website-scrap" && <SingleWebsiteScrap />}
            {view === "" && <Welcome />}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
