import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const SingleWebsiteScrap = () => {
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [scrapData, setScrapData] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState("");

  useEffect(() => {
    if (scrapData?.EmailDetails?.length === 1) {
      const singleEmail = scrapData.EmailDetails[0];
      setSelectedEmail(singleEmail);
      window.location.href = `mailto:${singleEmail}`;
    }
  }, [scrapData]);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    if (email) {
      setSelectedEmail(email);
      window.location.href = `mailto:${email}`;
    }
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      const response = await axios.get(
        `https://scrape-backend.marktivities.guru/scraper/websiteScrap/?website=${websiteUrl}`
      );

      if (response.status === 200) {
        setScrapData(response.data);
        toast.success("Information fetched from the given website");
      } else {
        toast.error("Error occurred");
      }
    } catch (error) {
      console.error("Error processing request", error);
      toast.error("Internal Server Error!");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-gray-50 p-6">
      <div className="bg-white p-8 border border-gray-200 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-3xl font-bold text-gray-900 mb-4 text-center">
          Single Website Scraper
        </h2>

        <p className="text-gray-700 text-lg mb-6 text-center">
          Enter the website URL below to scrape and display relevant details.
        </p>

        <div className="space-y-4">
          <input
            type="url"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="Enter website URL"
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-800 bg-gray-50"
            required
          />

          <button
            type="button"
            onClick={handleSubmit}
            className={`w-full py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 ${isProcessing ? 'bg-blue-400 cursor-not-allowed' : ''}`}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Scrape Website"}
          </button>

          {scrapData && (
            <div className="mt-6 space-y-6">
              <div className="text-gray-900 font-bold">
                <p className="text-lg">Results from: <span className="font-semibold">{websiteUrl}</span></p>
              </div>

              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">Emails Found:</h3>
                  <select
                    className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                    onChange={handleEmailChange}
                    value={selectedEmail}
                  >
                    <option value="" disabled>Select an email to contact</option>
                    {scrapData.EmailDetails.map((email, index) => (
                      <option key={index} value={email}>{email}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <h3 className="text-xl font-semibold mb-4">YouTube Channels:</h3>
                  {scrapData.YoutubeDetails.map((ytDetail, index) => (
                    <div key={index} className="mb-6">
                      <p className="text-blue-700 font-medium underline">
                        <a href={ytDetail.channel_url} target="_blank" rel="noopener noreferrer">
                          {ytDetail.channel_url}
                        </a>
                      </p>
                      {ytDetail.channel_details && ytDetail.channel_details.length > 0 && (
                        <div className="ml-4 mt-3 space-y-4 bg-gray-100 p-4 rounded-lg">
                          {ytDetail.channel_details.map((detail, idx) => (
                            <div key={idx} className="text-gray-700">
                              {Object.entries(detail).map(([channelName, channelInfo], i) => (
                                <div key={i} className="mt-2">
                                  <h4 className="font-semibold text-lg">{channelName}</h4>
                                  <p><strong>Subscribers:</strong> {channelInfo.Subscribers}</p>
                                  <p><strong>Videos Count:</strong> {channelInfo["Videos Count"]}</p>
                                  <p><strong>Total Views:</strong> {channelInfo["Total Views"]}</p>
                                  <div className="mt-2">
                                    <strong>Links:</strong>
                                    <div className="ml-2 space-y-1">
                                      {Object.entries(channelInfo.Links).map(([linkName, linkUrl], j) => (
                                        <p key={j} className="text-blue-600 underline">
                                          <a href={`https://${linkUrl}`} target="_blank" rel="noopener noreferrer">
                                            {linkName}
                                          </a>
                                        </p>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default SingleWebsiteScrap;
