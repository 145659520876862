import React, { useState } from "react";
import { FaCloudDownloadAlt, FaCloudUploadAlt } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import Papa from "papaparse";
import axios from "axios";
import { toast } from "react-toastify";
import "../css/CSVScrap.css";

const CSVScrap = () => {
  const [headers, setHeaders] = useState("");
  const [selectedHeaderFromCSV, setSelectedHeaderFromCSV] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [showDownloadCSVButton, setShowDownloadCSVButton] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState("");

  const getCSRFToken = () => {
    let csrfToken = null;
    if (document.cookie) {
      document.cookie.split(";").forEach((cookie) => {
        if (cookie.trim().startsWith("csrftoken=")) {
          csrfToken = decodeURIComponent(cookie.trim().substring(10));
        }
      });
    }
    return csrfToken;
  };


  const handleCSVSubmit = async () => {
    setLoading(true);
    try {
      if (!file || !email) {
        toast.error("Please upload a file and enter an email.");
        setLoading(false);
        return;
      }

      file.append("mail", email);
      file.append("columnName", selectedHeaderFromCSV);
      const csrfToken = getCSRFToken();

      const response = await axios.post(
        "https://scrape-backend.marktivities.guru/scraper/csvScrap/",
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrfToken,
          },
          responseType: Blob,
        }
      );

      // Create a link element to trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", selectedFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      if (response.data.status === 200) {
        toast.success("File Downloaded");
        setShowDownloadCSVButton(true);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Server error occurred");
      setLoading(false)
    }
  };

  const handleCSVUpload = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFileName("Result_" + selectedFile.name);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      setFile(formData);
      Papa.parse(selectedFile, {
        header: true,
        complete: (results) => {
          if (results.data.length > 0) {
            const headerNames = Object.keys(results.data[0]);
            setHeaders(headerNames);
          }
        },
        error: (error) => {
          console.error("Error parsing CSV file", error);
        },
      });
    }
  };

  const handleHeaderChange = (e) => {
    setSelectedHeaderFromCSV(e.target.value);
  };

  const handleDownloadCSV = async () => {
    try {

      const response = await axios.get(
        `https://scrape-backend.marktivities.guru/scraper/downloadcsv?filename=${filename}`,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("CSV File downloaded successfully");
      }
    } catch (error) {
      if (error.response) {
        // Handle errors from the backend
        if (error.response.status === 404) {
          toast.error(error.response.data.error || "File not found. Enter correct file name.");
        } else if (error.response.status === 400) {
          toast.error(error.response.data.error || "Invalid request");
        } else {
          toast.error("An unexpected error occurred");
        }
      } else {
        // Handle network or other errors
        toast.error("An error occurred while downloading the file");
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
          CSV Scrapper - Bulk Information Fetch
        </h2>
        <div className="flex justify-center mb-6">
          <FaCloudUploadAlt className="text-4xl text-blue-600" />
        </div>
        <input
          type="file"
          onChange={handleCSVUpload}
          accept=".csv"
          className="w-full mb-4 text-sm text-gray-800 bg-gray-100 border border-gray-300 rounded-md cursor-pointer file:bg-blue-600 file:text-white file:py-2 file:px-4 hover:file:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email"
          className="w-full mb-4 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-800 bg-gray-50"
        />

        {headers.length > 0 && (
          <div className="mb-6">
            <label
              htmlFor="header-dropdown"
              className="block mb-2 text-gray-700 font-medium"
            >
              Select a product from the CSV file:
            </label>
            <select
              id="header-dropdown"
              onChange={handleHeaderChange}
              className="w-full text-sm text-gray-800 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition px-3 h-[35px] mt-5"
            >
              <option value=""  >
                Select a product
              </option>
              {headers.map((header, index) => (
                <option key={index} value={header}>
                  {header}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex flex-col sm:flex-row sm:justify-between items-center space-y-4 sm:space-y-0">
          {file && email && (
            <button
              type="button"
              onClick={handleCSVSubmit}
              className="flex items-center px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-sm transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {loading ? "Processing" : "Submit"}
              <IoIosSend className="ml-2" />
            </button>
          )}
        </div>
        <p className="mt-5">
          {loading ? (
            <p className="text-lg">
              CSV files will be automatically downloaded once the process is
              completed
              <span className="dots"></span>
            </p>
          ) : (
            ""
          )}
        </p>

        <div className="mt-10">
          <p>
            If scraping is already completed, enter the filename below. The csv
            file will be downloaded.
          </p>
          <input
            type="input"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            placeholder="Enter filename"
            className="w-full mb-4 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-800 bg-gray-50"
          />

          <button
            onClick={handleDownloadCSV}
            className="flex items-center justify-center px-4 mx-auto w-full py-2 bg-green-600 text-white font-semibold rounded-md shadow-sm transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Download CSV
            <FaCloudDownloadAlt className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CSVScrap;